@font-face{
  font-family: Poppins;
  src: url(../src/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face{
  font-family: Poppins;
  src: url(../src/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

html,
body {
  font-family: Poppins;
  height: 100%
}

.card{
  width: 18rem !important;
  border-color: black;
  background-color: white;
  text-transform: uppercase;
  margin: 40px;
}

.card-title{
  color: black;
}

.card-text{
  color: #a29e9e
}

.card button{
  background-color: #00528d;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  width: 85%;
  border-color: #1e000000;
}

.card .card-title{
  font-weight: bold;
}

.logo{
  width: 150px;
}

.navbar { 
  padding: 0 
}

.central-banner-container{
  background: url(../src/images/banner_geo-01.jpg) no-repeat center center;
  background-size: cover;
  height: 300px;
}

.central-banner-container .transbox{
  background-color: rgba(0, 0, 0, 0.52);
}

.under-banner-textbox h2 {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.under-banner-textbox h4{
  text-transform: uppercase;
  color: #a29e9e;
}

.modal-header{
  height: 130px;
  background-color: #00528d;
  color: white;
  text-transform: uppercase;
}

.g-recaptcha {
  display: inline-block;
}
